<template>
  <div>
    <nav id="sidebar" class="sidebar--main" :class="{ active: isActive }">
      <div class="sidebar--header" ref="sidebarHeader">
        <div class="back--arrow" @click="back">
          <img src="@/assets/img/icons/back-1.svg" alt="Back" />
        </div>
        <div v-if="sidebarContent">
          <div class="sidebar--content-title">
            <span v-if="sidebarContent === 'rent'">{{ $t('general.rent') }}</span>
            <span v-else-if="sidebarContent === 'buy'">{{ $t('general.buy') }}</span>
          </div>
        </div>
        <div v-else-if="isLoggedIn">
          <div class="d-flex align-center">
            <img
              class="profile--picture"
              :src="user.photo_profile_user"
              :alt="user.name + ' photos'"
            />
            <div @click="goToPage('/profile')">
              <div class="profile--name">
                {{ user.name }}
              </div>
              <!--            <div class="profile&#45;&#45;since">-->
              <!--              Bergabung sejak 21 Sept 2020-->
              <!--            </div>-->
            </div>
          </div>
          <profile-membership
            style="
              display: block;
              margin-top: 8px;
              background-color: white;
              border-radius: 12px;
              padding: 8px 12px;
            "
            @onClick="closeSidebar"
          ></profile-membership>
        </div>
        <div v-else>
          <button class="btn btn-primary--inverse" @click="showLogin">
            {{ $t('loginRegister.title') }}
          </button>
        </div>
      </div>
      <sidebar-content :style="{ height: `calc(100vh - ${totalHeight}px)` }"></sidebar-content>
      <div class="sidebar--footer" ref="sidebarFooter">
        <!--        <div class="sidebar&#45;&#45;footer-item" @click="goToPage('/about-us')">{{ $t('footer.company.aboutUs') }}</div>-->
        <!--        <div class="sidebar&#45;&#45;footer-item" @click="goToPage('/contact-us')">{{ $t('general.contactUs') }}</div>-->
        <!--        <div class="sidebar&#45;&#45;footer-item" @click="goToPage('/career')">{{ $t('footer.company.career') }}</div>-->
      </div>
    </nav>
    <div class="overlay" @click="setSidebar" :class="{ active: isActive }"></div>
  </div>
</template>

<script>
import auth from '@/mixins/auth.js';
import { mapState } from 'vuex';

const SidebarContent = () => import('@/components/layouts/partials/sidebar-content');
import ProfileMembership from '@/components/utils/profile-membership';

export default {
  mixins: [auth],
  components: {
    SidebarContent,
    ProfileMembership,
  },
  data: () => ({
    totalHeight: 0,
  }),
  computed: {
    ...mapState({
      sidebarContent: (state) => state.global.sidebarContent,
    }),
  },
  methods: {
    showLogin() {
      this.closeSidebar();
      this.$modal.show('modal-login');
    },
    back() {
      if (this.sidebarContent) {
        this.$store.commit('global/SET_SIDEBAR_CONTENT', null);
      } else {
        this.setSidebar();
      }
    },
    calculateHeight() {
      if (this.$refs.sidebarHeader && this.$refs.sidebarFooter) {
        this.totalHeight =
          this.$refs.sidebarHeader.clientHeight + this.$refs.sidebarFooter.clientHeight;
      } else {
        this.totalHeight = 0;
      }
    },
    goToPage(route) {
      this.closeSidebar();
      this.$router.push(route);
    },
    closeSidebar() {
      this.$store.commit('global/SET_MAIN_SIDEBAR', false);
      this.$store.commit('global/SET_SIDEBAR_CONTENT', null);
    },
  },
  mounted() {
    this.calculateHeight();
  },
  watch: {
    sidebarContent() {
      setTimeout(() => {
        this.calculateHeight();
      }, 250);
    },
  },
};
</script>

<style>
.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1040;
  top: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.overlay.active {
  display: block;
  opacity: 1;
}
</style>
