<template>
  <div class="profile--membership" v-if="user && user.membership">
    <div class="membership--info">
      <div class="membership--name">{{ membershipType }}</div>
      <img class="membership--logo" :src="user.membership.logo" v-if="user.membership.logo" />
    </div>
    <div class="membership--expired">
      <span>{{ `${$t('general.endAt')} ` }}</span>
      <span>{{ $date.format(user.membership.expired_at, 'dd MMM yyyy') }}</span>
    </div>
  </div>
  <router-link to="/product" class="profile--membership" style="text-decoration: none" v-else>
    <div class="membership--info" @click="onClick">
      <div class="membership--name">{{ $t('general.noMembership') }}</div>
    </div>
    <div class="membership--expired" @click="onClick">
      {{ $t('general.buyMembershipNow') }}
    </div>
  </router-link>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  components: {},
  props: [],
  computed: {
    ...mapState({
      user: (state) => state.global.user,
    }),
    ...mapGetters({
      membershipType: 'global/membershipType',
    }),
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.profile--membership {
  .membership--info {
    display: flex;
    align-items: center;
  }
  .membership--name {
    font-family: Poppins-Bold;
    color: $color-text-gray-2;
  }
  .membership--logo {
    width: 18px;
    margin-left: 8px;
  }
  .membership--expired {
    font-size: $font-xs;
    font-family: Poppins;
    color: $color-text-black;
  }
}
</style>
